var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      _vm.albumList && _vm.albumList.length
        ? _c(
            "div",
            { staticClass: "album" },
            _vm._l(_vm.albumList, function (item) {
              return _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.albumDetail(item)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src:
                        item.folderIcon ||
                        require("../../assets/img/home/albumico.png"),
                      alt: "",
                    },
                  }),
                  _c("span", { staticClass: "item_text" }, [
                    _vm._v(_vm._s(item.folderName)),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.fileTotal))]),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "content" }, [
            _c("img", {
              staticClass: "default",
              attrs: {
                src: require("../../assets/img/home/home_img_default@2x.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "info" }, [
              _c("span", {
                staticStyle: { "text-align": "center" },
                domProps: { innerHTML: _vm._s(_vm.$t("lang.uploadTips")) },
              }),
            ]),
          ]),
      _c("img", {
        staticClass: "btn_add",
        attrs: { src: require("../../assets/img/home/btn_add.png"), alt: "" },
        on: { click: _vm.add },
      }),
      _c(
        "van-dialog",
        {
          attrs: { title: "标题", "show-cancel-button": "" },
          on: { confirm: _vm.confirm },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "van-cell-group",
            { attrs: { border: true } },
            [
              _c("van-field", {
                attrs: { placeholder: "请输入相册名称" },
                model: {
                  value: _vm.photoName,
                  callback: function ($$v) {
                    _vm.photoName = $$v
                  },
                  expression: "photoName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }