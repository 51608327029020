<template>
  <div class="wrapper">
    <Header></Header>
    <div class="album" v-if="albumList && albumList.length">
      <div v-for="item in albumList" class="item" @click="albumDetail(item)">
        <img :src="item.folderIcon || require('../../assets/img/home/albumico.png')" alt="">
        <span class="item_text">{{item.folderName}}</span>
        <span>{{item.fileTotal}}</span>
      </div>
    </div>
    <div class="content" v-else>
      <img src="../../assets/img/home/home_img_default@2x.png" alt="" class="default">
      <div class="info">
<!--        <span>上传你珍贵的照片和视频</span>-->
<!--        <span>分享美好时光</span>-->
        <span style="text-align: center" v-html="$t('lang.uploadTips')"></span>
      </div>
    </div>
    <img src="../../assets/img/home/btn_add.png" alt="" class="btn_add" @click="add">
    <van-dialog v-model="show" title="标题" show-cancel-button @confirm="confirm">
      <van-cell-group :border="true">
        <van-field v-model="photoName" placeholder="请输入相册名称" />
      </van-cell-group>
    </van-dialog>
    <router-view></router-view>
<!--    <router-view name="detail"></router-view>-->
  </div>
</template>

<script>
import Header from 'components/header/Header'
import { CellGroup, Field } from 'vant'
import { queryFindMetaData, getMyMetaPhotoFolder } from 'api/api'
import { mapState, mapMutations } from 'vuex'
export default {
  name: "AlbumList",
  data() {
    return {
      show: false,
      photoName: '',
      encrypt: 0,
      attachments: [],
      blobSize: 0,
      albumList: []
    }
  },
  components: {
    Header,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  filters: {},
  directives: {},
  computed: {
    ...mapState(['token']),
    txId() {
      return localStorage.getItem('showId')
    }
  },
  created() {
    console.log(this.token)
  },
  mounted() {
    // let no = 0
    // var albumList = []
    // const params = {
    //   find: {
    //     $and: [{
    //       $or: [{
    //         'parentNodeName': 'SimplePhotoAlbum',
    //         // 'data.photos': {
    //         // 	"$regex": 'metafile://'
    //         // },
    //         'rootTxId': localStorage.getItem('showId')
    //       },
    //       ],
    //     }, ],
    //     metaId: process.env.VUE_APP_MetaIdTag,
    //     isNew: true,
    //   },
    //   sort: {
    //     timestamp: 1
    //   },
    //   skip: 1,
    //   limit: 9999
    // }
    // queryFindMetaData(params).then(res => {
    //   res.result.data.forEach((item, key) => {
    //
    //     //文件夹统计
    //     let isNew = true;
    //     for (var i = 0; i < albumList.length; i++) {
    //       if (albumList[i]['albumName'] == item.data.name) {
    //
    //         var photosimgArr = []
    //         if (albumList[i]['photosimg'].length > 0) {
    //           albumList[i]['photosimg'].forEach((i, k) => {
    //
    //             photosimgArr.push(i);
    //           })
    //         }
    //
    //         if (item.data.hasOwnProperty('photos') && Array.isArray(item.data.photos)) {
    //
    //           item.data.photos.forEach((i, k) => {
    //             photosimgArr.push(i)
    //           });
    //
    //           var albumListSrc = require('../../assets/img/home/albumico.png');
    //
    //           if(item.data.photos[0] != undefined) {
    //             console.log("zsaswzxzxxzasasddsas",item.data.photos[0])
    //             if(item.data.photos[0].startsWith('data:')){
    //
    //               albumListSrc = item.data.photos[0]
    //             }else{
    //
    //               albumListSrc = `${process.env.VUE_APP_AppImgApi}/metafile/compress/` + item.data.photos[0].toString().replace(new RegExp(
    //                   'metafile://', 'ig'), "");
    //             }
    //           }else{
    //             console.log(item.data)
    //             console.log("看看这里定位2")
    //           }
    //
    //           var album = {
    //             id: no,
    //             albumName: item.data.name,
    //             albumNum: albumList[i]['albumNum'] + item.data.photos.length,
    //             albumListSrc: albumListSrc,
    //             photosimg: photosimgArr
    //           }
    //
    //         } else {
    //
    //           console.log("看看这里定位3")
    //           console.log(photosimgArr)
    //           var albumListSrc = require('../../assets/img/home/albumico.png');
    //           if(photosimgArr.length > 0) {
    //             if(!photosimgArr[photosimgArr.length - 1].startsWith("data:")){
    //
    //               albumListSrc = `${process.env.VUE_APP_AppImgApi}/metafile/compress/` + photosimgArr[photosimgArr.length - 1].replace(new RegExp(
    //                   'metafile://', 'ig'), "");
    //             }
    //
    //           }
    //           var album = {
    //             id: no,
    //             albumName: item.data.name,
    //             albumNum: albumList[i]['albumNum'],
    //             albumListSrc: albumListSrc,
    //             photosimg: photosimgArr
    //           }
    //         }
    //         albumList[i] = album;
    //         isNew = false;
    //       }
    //       no++;
    //     }
    //     if (isNew) {
    //       if (item.data.hasOwnProperty('photos') && Array.isArray(item.data.photos)) {
    //
    //         var photosimgArr = []
    //         item.data.photos.forEach((i, k) => {
    //           photosimgArr.push(i)
    //         });
    //         console.log("看看这里")
    //         console.log(item.data.photos)
    //         var albumListSrc = '';
    //
    //         if(item.data.photos[0] != undefined ) {
    //
    //           if(item.data.photos[0].startsWith('data:')){
    //             albumListSrc = item.data.photos[0]
    //           }else{
    //
    //             albumListSrc = `${process.env.VUE_APP_AppImgApi}/metafile/compress/` + item.data.photos[0].toString().replace(new RegExp(
    //                 'metafile://', 'ig'), "");
    //
    //           }
    //         } else{
    //           console.log("看看这里定位")
    //         }
    //         var album = {
    //           id: no,
    //           albumName: item.data.name,
    //           albumNum: item.data.photos.length + 0,
    //           albumListSrc: albumListSrc,
    //           photosimg: photosimgArr
    //         }
    //         console.log(album)
    //       } else {
    //
    //         var album = {
    //           id: no,
    //           albumName: item.data.name,
    //           albumNum: 0,
    //           albumListSrc: require('../../assets/img/home/albumico.png'),
    //           photosimg: []
    //         }
    //       }
    //       no++
    //       albumList.push(album)
    //     }
    //   })
    //   no++
    //   console.log("相册初始化完成")
    //   debugger
    //   // localStorage.setItem('albumList', albumList)
    //   this.albumList = albumList
    // })

    this.getMyMetaPhotoFolder()
  },
  methods: {
    getMyMetaPhotoFolder() {
      let params = {
        metaId: localStorage.getItem('showId'),
        page: 1,
        pageSize: 15,
        timestamp: 0,
        folderMetanetId: ''
      }
      getMyMetaPhotoFolder(params).then(res => {
        this.albumList = res.data.results.items
      })
    },
    add() {
      if (this.$store.state.userInfo) {
        this.show = true
      } else {
        this.$router.push({
          name: 'User2',
          params: {
            txId: this.txId
          }
        })
      }
    },
    confirm(e) {
      if (!this.photoName) {
        this.$toast('相册名称不能为空')
        return
      }

      let data = {
        "folderName": this.photoName,
        "parentID": ''
      }

      const txData={
        nodeName: 'metaphotofolder',
        brfcId: '3b206054eb6b',
        path: '/Protocols/metaphotofolder',
        metaIdTag: process.env.VUE_APP_MetaIdTag,
        dataType: 'application/json',
        payCurrency: 'bsv',
        payTo: [{
          amount: 500,
          address: process.env.VUE_APP_PlatformAddress
        }, ],
        checkOnly:false,
        data: JSON.stringify(data),
        attachments: this.attachments,
      }

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.$toast(this.$t('lang.success'))
        this.getMyMetaPhotoFolder()
      }, (err) => {

      })
    },
    albumDetail(item) {
      this.$router.push({
        path: this.$route.path + '/detail',
        query: {
          name: item.folderName,
          metaId: item.metaId,
          metanetId: item.metanetId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 600Px;
    margin: 0 auto;
    .album {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      .item {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        cursor: pointer;
        img {
          width: 105px;
          height: 105px;
          border-radius: 6px;
          padding: 5px;
        }
        span {
          margin-left: 10px;
          height: 14px;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: #bfc2cc;
        }
        .item_text {
          height: 20px;
          font-size: 14px;
          font-family: SF Pro;
          font-weight: 500;
          line-height: 20px;
          color: #303133;
          margin-top: 5px;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 140px;
      .default {
        width: 125px;
        height: 104px;
      }
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 14px;
        color: #BFC2CC;
      }
    }
    .btn_add {
      position: absolute;
      right: 2px;
      bottom: 65px;
      cursor: pointer;
    }
  }
</style>
